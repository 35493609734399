import * as React from 'react';
import { graphql } from "gatsby";
import { IGallery } from '../types/contentfulTypes';
import StaticPage from '../components/StaticPage/StaticPage';

type Props = {
  data: {
    galleryData: IGallery
  }
}

export const query = graphql`
  query($locale: String) {
    galleryData: contentfulGallery(
      node_locale: { eq: $locale }
      slug: { eq: "gallery" }
    ) {
      title
      photos {
        photo {
          file {
            url
          }
        }
      }
    }
  }
`

const Gallery: React.FC<Props> = (props: Props) => {
  const galleryPhotos = props.data.galleryData.photos.map(i => i.photo);

  return(
    <StaticPage 
      header={props.data.galleryData.title}
      images={galleryPhotos} />
  )
}

export default Gallery
